.App {
  text-align: center;
  background-color: green;
  height: 100dvh;
}

.instructions {
  color: yellow;
  position: fixed;
  width: 100%;
  z-index: 15;
}