.filterButton {
    background-color: rgb(216, 2, 2);
    color: rgb(255, 255, 255);
    border-color: rgb(190, 190, 190);
    border-radius: 0.5rem;
    width: 5rem;
}

.filterButton.off {
    background-color: rgb(199, 199, 199);
    color: rgb(45, 45, 45);
    border-color: red;
    border-style: inset;
}