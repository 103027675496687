.pokeball {
    width: 40px;
    height: 40px;

}

.pokeballBar {
    text-align: center;
    display: flex;
    justify-content: center;
}

.pokeball>img {
    width: 100%;
    height: 100%
}

.pokeball:hover {
    cursor: grab;
}