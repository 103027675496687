.filterButtons {

    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 1rem;
}

.filterButtonsBox>h1 {
    color: rgb(252, 239, 0);
    margin: 0;
    padding: 1rem;
}

.filterButtonsBox {
    background-color: rgb(69, 19, 19);
    position: sticky;
    top: 0;
    z-index: 6;
    width: 100%;
}